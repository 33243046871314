import { createSlice } from "@reduxjs/toolkit";

import geo from "../translations/geo";
import en from "../translations/en";
import arm from "../translations/arm";
import rus from "../translations/rus";

const translations = { en, geo, arm, rus };

const getInitialLanguage = () => {
  const savedLanguage = localStorage.getItem("language") ? localStorage.getItem("language") : 'geo';
  return savedLanguage;
};

const initialLanguage = getInitialLanguage();

const languageSlice = createSlice({
  name: "language",
  initialState: {
    language: initialLanguage,
    translations: translations['en'],
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      state.translations = translations[action.payload];
      localStorage.setItem("language", action.payload);
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
